import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const PAGE_SIZE = 20;

export const TBody = ({ children }) => {
  const tableBodyRef = useRef(null);
  const [usePagination, setUsePagination] = useState(true);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [page, setPage] = useState(0);
  const totalPages = Math.ceil(children.length / PAGE_SIZE);
  const next = () => setPage(Math.min(page + 1, totalPages - 1));
  const prev = () => setPage(Math.max(page - 1, 0));

  useEffect(() => {
    // If has an ID, don't use pagination to show the entire table and download
    if (tableBodyRef.current && tableBodyRef.current.parentNode) {
      const table = tableBodyRef.current.parentNode.closest('table');
      if (table?.id) setUsePagination(false);
    }
  }, []);

  useEffect(() => {
    setRowsToShow(children.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE));
  }, [page]);

  return (
    <>
      <tbody ref={tableBodyRef}>{usePagination ? rowsToShow : children}</tbody>
      {usePagination && children.length > PAGE_SIZE && (
        <tfoot>
          <tr>
            <td colSpan="1000">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 13,
                  gap: 6,
                }}
              >
                <Icon
                  onClick={() => prev()}
                  as={MdChevronLeft}
                  boxSize={5}
                  color={page > 0 ? '#7e95ce' : '#cddbff'}
                  style={{ cursor: page > 0 ? 'pointer' : 'initial' }}
                />
                <div>
                  Page {page + 1} of {totalPages}
                </div>
                <Icon
                  onClick={() => next()}
                  as={MdChevronRight}
                  boxSize={5}
                  color={page < totalPages - 1 ? '#7e95ce' : '#cddbff'}
                  style={{
                    cursor: page < totalPages - 1 ? 'pointer' : 'initial',
                  }}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      )}
    </>
  );
};
