import React from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';

import { AuthenticationProvider, DataProvider } from './context';
import Routes from './Routes';

import { theme } from './theme';
import '@fontsource/poppins';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const isWidget = queryParams.get('mode') === 'widget';

  return (
    <AuthenticationProvider>
      <DataProvider>
        <ChakraProvider theme={theme}>
          <Box
            height="100vh"
            w="100vw"
            bgColor="#f7f9ff"
            p={isWidget ? '0px' : '24px'}
          >
            <Routes />
          </Box>
        </ChakraProvider>
      </DataProvider>
    </AuthenticationProvider>
  );
}

export default App;
