import { http } from 'services/http';

const REACT_APP_BUCKET_ENDPOINT = process.env.REACT_APP_BUCKET_ENDPOINT;
const REACT_APP_BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

export const buildPublicUrl = (file, bucket = REACT_APP_BUCKET_NAME) => {
  return `${REACT_APP_BUCKET_ENDPOINT}/${bucket}/${file}`;
};

export const getPresignedUrl = filename => http.get(`upload/${filename}`);
