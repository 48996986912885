import React from 'react';

import { Box } from '@chakra-ui/react';
import styles from './TypingLoader.module.css';

export const TypingLoader = ({ ...props }) => (
  <Box className={styles.bouncingLoader} {...props}>
    <Box className={styles.bouncingLoaderItem}></Box>
    <Box
      className={`${styles.bouncingLoaderItem} ${styles.bouncingLoaderItem2}`}
    ></Box>
    <Box
      className={`${styles.bouncingLoaderItem} ${styles.bouncingLoaderItem3}`}
    ></Box>
  </Box>
);
