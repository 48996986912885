import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Icon,
  Link,
  Text,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';

import { TypingLoader } from 'components';
import { SendMessageInput } from './SendMessageInput';

import { ask } from 'services';
import { useTimer, useFileUpload } from 'hooks';
import { useAuthenticationContext, useDataContext } from 'context';
import { Answer, Question, Error } from './Messages';

import { MdOutlinePushPin, MdKeyboardArrowDown } from 'react-icons/md';
import styles from './Chat.module.css';

import logoImg from 'assets/logo_cogniflow.svg';
import { FetchError } from 'utils';

const TIME_FOR_CHAT_EXPIRATION = 3 * 3600000; // 3 hours

export const Chat = ({
  lang,
  title,
  description,
  welcomeMessage,
  pinnedPreviewMessage,
  pinnedMessage,
  pinnedMessageIsCollapsible,
  avatar,
  avatarUrl,
  lang_code,
  fontFamily,
  colorButton,
  colorBackground,
  hideLogo,
  typingAnimation,
  idAgent,
  idModel,
  idApplication,
  webhookUrl,
  includeMessageSources,
  maxImageWidth,
  isFileUploadEnabled,
}) => {
  const { data } = useDataContext();
  const { getUserApiKey } = useAuthenticationContext();
  const [showExpirationDialog, setShowExpirationDialog] = useState(false);
  const { reset: resetTimer } = useTimer({
    time: TIME_FOR_CHAT_EXPIRATION,
    onFinish: () => setShowExpirationDialog(true),
  });
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const idSession = useRef(uuidv4());

  const intervalId = useRef(0);
  const answerRefs = useRef([]);
  const chatWindowRef = useRef();
  const [shiftPressed, setShiftPressed] = useState(false);
  const {
    loading: fileLoading,
    files,
    clearFiles,
    removeFile,
    handleFileChange,
    uploadFiles,
  } = useFileUpload();

  const textAreaRef = useRef();

  const [chat, setChat] = useState([
    {
      text:
        welcomeMessage ||
        `Hi! 👋 I'm **${
          data.chatbotName || 'CogniGPT'
        }**! What can I help you with today?`,
      type: 'answer',
    },
  ]);

  const clearScrollInterval = useCallback(() => {
    window.clearInterval(intervalId.current);
    intervalId.current = 0;
  }, []);

  const scrollToBottom = useCallback(() => {
    chatWindowRef.current.scrollTo({
      top: chatWindowRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  const scrollTimeout = useCallback(() => {
    intervalId.current = setInterval(() => {
      scrollToBottom();
    }, 400);
  }, [scrollToBottom]);

  const abortControllerRef = useRef(null);

  const [inputVal, setInputVal] = useState('');

  const onChageHandler = e => {
    if (e.target.value === '\n' && !e.target.value.trim()) return;
    setInputVal(e.target.value);
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat, scrollToBottom]);

  const setMessage = ({
    text,
    type,
    sources,
    cancelled,
    isTruncated = false,
    reference,
  }) => {
    setChat(s => [
      ...s,
      {
        text,
        type,
        sources,
        cancelled,
        isTruncated,
        reference,
      },
    ]);
  };

  const setLoadingStates = value => {
    setLoading(value);
    setIsTyping(value);
  };

  const askFetch = useCallback(
    question => {
      if (!question) return;
      abortControllerRef.current = new AbortController();

      clearScrollInterval();
      scrollTimeout();

      setLoadingStates(true);

      ask({
        question,
        signal: abortControllerRef.current.signal,
        idModel: idModel || idApplication,
        idAgent,
        idSession: idSession.current,
        lang_code,
        apiKey: idApplication ? '' : getUserApiKey(),
      })
        .then(res => {
          resetTimer();

          let response = idAgent
            ? {
                processing_time: -1.0,
                result: [
                  {
                    answer: res.answer,
                    short_answer: '',
                    context: '',
                    confidence: -1.0,
                    reference: '',
                    is_truncated: false,
                  },
                ],
              }
            : res;

          for (const item of response.result) {
            setMessage({
              text: item.answer,
              type: 'answer',
              sources: item.sources,
              isTruncated: item.is_truncated,
              reference: item.reference,
            });
          }
          setLoading(false);

          // Send the result to the webhook
          if ((idApplication || idAgent) && webhookUrl)
            fetch(webhookUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                payload: { question, id_session: idSession.current },
                response,
              }),
            }).catch(() => {});
        })
        .catch(err => {
          setLoadingStates(false);
          if (err instanceof FetchError) {
            if (idApplication || idAgent) {
              setMessage({
                text: 'The chatbot is not currently available. Please reach out using a different channel or check back later. Thanks.',
                type: 'unavailable',
                sources: [],
              });
              return;
            } else {
              setMessage({
                text: err.message,
                type: 'unavailable',
                sources: [],
              });
              return;
            }
          }
          if (err.name !== 'AbortError') {
            setMessage({
              text: 'Something went wrong',
              type: 'error',
              sources: [],
              reference: '',
            });
          } else {
            setMessage({
              text: '',
              type: 'error',
              sources: [],
              cancelled: true,
            });
          }
        });
    },
    [
      idSession,
      idModel,
      idApplication,
      idAgent,
      scrollTimeout,
      clearScrollInterval,
    ]
  );

  const onAskHander = async () => {
    if (!inputVal.trim() && !files.length > 0) return;
    if (fileLoading) return;
    let question = inputVal;
    setInputVal('');

    if (files.length > 0) {
      question +=
        '\n' +
        files
          .map(
            file =>
              `![${capitalize(file.type)} file, fileType:${file.type}](${
                file.url
              })`
          )
          .join('\n');
      clearFiles();
    }

    setMessage({ text: question, type: 'question', sources: [] });

    askFetch(question);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 16) {
      setShiftPressed(true);
    }
    if (event.keyCode === 13 && !shiftPressed && !sendMessageIsDisabled) {
      onAskHander();
    }
  };

  const handleKeyUp = event => {
    event.target.style.height = '42px';
    event.target.style.height = `${event.target.scrollHeight}px`;
    event.keyCode === 16 && setShiftPressed(false);
  };

  const retry = () => {
    const chatTemp = [...chat];

    chatTemp.pop();

    setChat(chatTemp);

    askFetch(chatTemp[chatTemp.length - 1].text);
  };

  const stopRequest = () => {
    const lastAnswer = answerRefs.current[answerRefs.current.length - 1];

    abortControllerRef.current.abort();
    lastAnswer.stopAnimation();
    setIsTyping(false);
    clearScrollInterval();
  };

  const onFinish = () => {
    textAreaRef.current.focus({ preventScroll: true });
    setIsTyping(false);
    clearScrollInterval();
  };

  useEffect(() => {
    abortControllerRef.current = new AbortController();
  }, []);

  useEffect(() => {
    if (!chatWindowRef.current) return;

    let chatWindowRefValue = chatWindowRef.current;

    let scrollPos = chatWindowRef.current.scrollTop;

    const clearScrollFn = e => {
      if (chatWindowRef.current.scrollTop < scrollPos) {
        clearScrollInterval();
      }

      const { scrollHeight, scrollTop, clientHeight } = chatWindowRef.current;

      if (Math.floor(scrollHeight - scrollTop) === clientHeight) {
        if (!intervalId.current) {
          scrollTimeout();
        }
      }

      scrollPos = chatWindowRef.current.scrollTop;
    };

    chatWindowRef.current.addEventListener('scroll', clearScrollFn);

    return () => {
      if (chatWindowRefValue.current) {
        chatWindowRefValue.current.removeEventListener('scroll', clearScrollFn);
      }

      window.clearInterval(intervalId.current);
    };
  }, [clearScrollInterval, scrollTimeout]);

  // Pinned Message
  const pinnedWrapperRef = useRef(null);
  const pinnedMessageRef = useRef(null);
  const pinnedMessageHeight = 24;
  const [showPinnedMessage, setShowPinnedMessage] = useState(
    pinnedMessageIsCollapsible === false
  );
  const [showPinnedMessageBtn, setShowPinnedMessageBtn] = useState(false);

  const handlePinnedMessageBtnClick = () => {
    if (!showPinnedMessageBtn) return;
    pinnedWrapperRef.current.scrollTop = 0;
    setShowPinnedMessage(prev => !prev);
  };

  useEffect(() => {
    const updateBtn = () => {
      setShowPinnedMessageBtn(
        (pinnedMessageIsCollapsible ?? true) &&
          pinnedMessageRef.current &&
          pinnedMessageRef.current.offsetHeight > pinnedMessageHeight + 5
      );
    };
    updateBtn();
    window.addEventListener('resize', updateBtn);
    return () => {
      window.removeEventListener('resize', updateBtn);
    };
  }, []);

  const sendMessageIsDisabled = useMemo(
    () => loading || isTyping,
    [loading, isTyping]
  );

  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <Box
        overflow="hidden"
        bgColor="white"
        h="100%"
        borderRadius="10px"
        boxShadow="md"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        style={{
          '--color': colorButton,
          '--font-family': fontFamily || 'Poppins',
          backgroundColor: colorBackground || '#fff',
        }}
        className={styles.wrapper}
      >
        <Box className={styles.header}>{title}</Box>

        {pinnedMessage && (
          <Box
            ref={pinnedWrapperRef}
            onClick={handlePinnedMessageBtnClick}
            p="16px"
            display="flex"
            flexShrink={0}
            boxShadow="0 1px 8px 0 rgba(72, 79, 101, 0.18)"
            cursor={showPinnedMessageBtn && 'pointer'}
            style={{
              overflow: 'auto',
              position: 'relative',
            }}
          >
            <Icon
              as={MdOutlinePushPin}
              boxSize={4}
              mt="4px"
              mr="10px"
              color={colorButton || '#ff6a61'}
              position="absolute"
            />
            <Box
              px="24px"
              flexGrow={1}
              className={styles.message}
              style={{
                maxHeight: 500,
                ...(!showPinnedMessage
                  ? {
                      overflow: 'hidden',
                      height: pinnedMessageHeight,
                    }
                  : {}),
              }}
            >
              <Box ref={pinnedMessageRef}>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm, remarkBreaks]}
                  className={styles.reactMarkDown}
                  linkTarget="_blank"
                >
                  {(pinnedPreviewMessage && showPinnedMessage === false
                    ? `${pinnedPreviewMessage}\n`
                    : '') + pinnedMessage}
                </ReactMarkdown>
              </Box>
            </Box>
            {showPinnedMessageBtn && (
              <Box height="20px" position="absolute" right="20px">
                <Icon
                  as={MdKeyboardArrowDown}
                  boxSize={5}
                  cursor="pointer"
                  transition={'.1s'}
                  transform={
                    showPinnedMessage ? 'rotate(-180deg)' : 'rotate(0deg)'
                  }
                />
              </Box>
            )}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gridGap="12px"
          p="16px"
          className={styles.content}
          ref={chatWindowRef}
        >
          {chat.map(
            (
              { text, type, sources, cancelled, isTruncated, reference },
              index
            ) => (
              <Box key={index}>
                {type === 'answer' && (
                  <Answer
                    type={type}
                    sources={sources}
                    ref={currentRef => {
                      answerRefs.current.push(currentRef);
                    }}
                    onFinish={onFinish}
                    index={index}
                    lang={lang}
                    avatar={avatar}
                    avatarUrl={avatarUrl}
                    typingAnimation={typingAnimation}
                    isTruncated={isTruncated}
                    includeMessageSources={includeMessageSources}
                    reference={reference}
                    maxImageWidth={maxImageWidth}
                  >
                    {text}
                  </Answer>
                )}
                {type === 'question' && (
                  <Question maxImageWidth={maxImageWidth}>{text}</Question>
                )}
                {type === 'error' && (
                  <Error retry={retry} cancelled={cancelled}>
                    {text}
                  </Error>
                )}
                {type === 'unavailable' && (
                  <Error
                    retry={retry}
                    cancelled={cancelled}
                    color={'#646c84'}
                    showRetry={false}
                  >
                    {text}
                  </Error>
                )}
              </Box>
            )
          )}

          {loading && <TypingLoader mt="12px" mb="26px" />}
        </Box>

        <Box p="16px" position="relative">
          {(loading || isTyping) && (
            <Box
              padding="8px 12px"
              border="1px solid"
              borderColor="#ff6a61"
              position="absolute"
              top={-25}
              left="50%"
              transform="translateX(-50%)"
              borderRadius="4px"
              color="#ff6a61"
              cursor="pointer"
              backgroundColor="white"
              onClick={stopRequest}
            >
              <Text fontSize="12px">Stop request</Text>
            </Box>
          )}
          <SendMessageInput
            onClick={onAskHander}
            onChange={onChageHandler}
            value={inputVal}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            ref={textAreaRef}
            colorButton={colorButton}
            files={files}
            clearFiles={clearFiles}
            removeFile={removeFile}
            handleFileChange={handleFileChange}
            isFileUploadEnabled={isFileUploadEnabled}
          />
        </Box>

        {!hideLogo && (
          <Link
            href="https://www.cogniflow.ai?utm_source=website&utm_medium=chatbot-poweredby"
            target="_blank"
            className={styles.footer}
          >
            <Box className={styles.footer__content}>
              Powered by
              <img src={logoImg} />
            </Box>
          </Link>
        )}
      </Box>

      {/* Expiration Dialog */}
      <AlertDialog
        isOpen={showExpirationDialog}
        onClose={() => setShowExpirationDialog(false)}
        isCentered
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Conversation ended
            </AlertDialogHeader>

            <AlertDialogBody>
              You will start a new conversation so the previous dialog will not
              be remembered.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setShowExpirationDialog(false)}>Ok</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
