import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';

import Renderers from 'components/Renderers';
import { MessageWrapper } from './MessageWrapper';

import styles from './Question.module.css';

export const Question = ({ children, maxImageWidth }) => (
  <MessageWrapper justifyContent="flex-end">
    <Box
      bgColor="#484f79"
      p="8px 12px"
      borderRadius="14px"
      borderTopRightRadius={0}
      maxW="100%"
      boxShadow="0 1px 8px 0 rgba(72, 79, 101, 0.18)"
      style={{ '--maxImageWidth': maxImageWidth || '100%' }}
      className={styles.questionMarkDown}
    >
      <Text as="span" color="#fff" fontSize="14px">
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkBreaks]}
          components={Renderers}
          linkTarget="_blank"
        >
          {children}
        </ReactMarkdown>
      </Text>
    </Box>
  </MessageWrapper>
);
