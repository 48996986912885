import React, {
  memo,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { FetchError } from 'utils';
import { useAuthenticationContext } from './AuthenticationContext';
import { getApplication, getExperiment, getModel, getAgent } from 'services';

export const DataContext = createContext();

export const useDataContext = () => {
  return useContext(DataContext);
};

export const DataProvider = memo(({ children }) => {
  const { isAuthenticated, redirectToLogin } = useAuthenticationContext();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [application, setApplication] = useState(null);

  const logout = () => {
    localStorage.removeItem('demo-code');
    window.location.href = '/';
  };

  const validateExperiment = experiment => {
    if (
      experiment.task !== 3 ||
      experiment.config?.qa_processor_type !== 'generative'
    )
      throw new Error();
  };

  const loadModelById = async idModel => {
    const { id_experiment: idExperiment } = await getModel(idModel);
    const experiment = await getExperiment(idExperiment);
    validateExperiment(experiment);
    const { lang, title, config: configExp } = experiment;
    setData({
      lang,
      title,
      description: '',
      ...(configExp || {}),
      typingAnimation: experiment.config?.knowledge_base_data_type !== 1,
    });

    if (window.location.pathname !== '/chat')
      window.location.href = `/chat?idModel=${idModel}`;
  };

  const loadApplicationById = async idApplication => {
    const application = await getApplication(idApplication);
    setApplication(application);
    const { experiment, config, title } = application;
    // IMPORTANT!! Don't use getExperiment() as it gives authentication problems.
    validateExperiment(experiment);
    const { title: titleExp, lang, config: configExp } = experiment;
    setData({
      lang,
      title: title || titleExp,
      description: '',
      ...(configExp || {}),
      ...(config || {}),
      typingAnimation: experiment.config?.knowledge_base_data_type !== 1,
    });

    if (window.location.pathname !== '/chat')
      window.location.href = `/chat?idApplication=${idApplication}`;
  };

  const loadAgentById = async idAgent => {
    const agent = await getAgent(idAgent);
    setData(agent.app_config || {});

    if (window.location.pathname !== '/chat')
      window.location.href = `/chat?idAgent=${idAgent}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!['/', '/chat'].includes(window.location.pathname)) return;

      setError(null);
      const queryParams = new URLSearchParams(window.location.search);
      const idModel = queryParams.get('idModel');
      const idApplication = queryParams.get('idApplication');
      const idAgent = queryParams.get('idAgent');

      try {
        if (idModel) {
          if (isAuthenticated === true) await loadModelById(idModel);
          else if (isAuthenticated === false)
            redirectToLogin({ next: `/chat?idModel=${idModel}` });
        } else if (idApplication) {
          await loadApplicationById(idApplication);
        } else if (idAgent) {
          await loadAgentById(idAgent);
        } else if (window.location.pathname === '/chat')
          window.location.href = '/';
      } catch (error) {
        if (error instanceof FetchError && error.statusCode === 401)
          setError('You are not allowed to access this model');
        else setError('An error occurred when loading the model');
      }
    };
    fetchData();
  }, [isAuthenticated, setError, redirectToLogin]);

  return (
    <DataContext.Provider
      value={{
        data,
        error,
        logout,
        application,
      }}
    >
      {children}
    </DataContext.Provider>
  );
});
