import React from 'react';
import { Box, Container, Alert, AlertIcon } from '@chakra-ui/react';

import { useDataContext } from 'context';
import { Chat as ChatComponent } from '../components/Chat';
import { useQueryParams } from 'hooks';

export const Chat = () => {
  const { reqCode, data, error } = useDataContext();
  const idAgent = useQueryParams('idAgent');
  const idModel = useQueryParams('idModel');
  const idApplication = useQueryParams('idApplication');
  const isWidget = useQueryParams('mode') === 'widget';

  if (error)
    return (
      <Container maxW="2xl" centerContent>
        <Alert status="warning">
          <AlertIcon />
          {error}
        </Alert>
      </Container>
    );

  return (
    <Box
      margin="auto"
      maxW={isWidget ? '100%' : '800px'}
      maxH={isWidget ? '100%' : '800px'}
      h="100%"
    >
      {data && (
        <ChatComponent
          {...data}
          idAgent={idAgent}
          idModel={idModel}
          idApplication={idApplication}
        />
      )}
    </Box>
  );
};
