import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Center,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';

import { getExperiment, getExperiments, buildPublicUrl } from 'services';

import imgRobot from './robot.jpg';

export const Experiment = () => {
  const navigate = useNavigate();
  const [experiments, setExperiments] = useState([]);

  useEffect(() => {
    getExperiments().then(res => {
      setExperiments(res.filter(item => item.task === 3));
    });
  }, []);

  const handleOpenExperiment = experimentId => {
    getExperiment(experimentId).then(res => {
      const modelId = res.models[0].id;
      const url = `/chat?idModel=${modelId}`;
      window.open(url, '_self');
    });
  };

  return (
    <Center>
      <Box w="1100px">
        <Text fontSize="4xl">My Experiments</Text>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }} spacing={8} mt={10}>
          {experiments.map(experiment => (
            <Card key={experiment.id}>
              <CardBody>
                <AspectRatio maxW='560px' ratio={1.5}>
                  <Image
                    src={experiment.logo ? buildPublicUrl(experiment.logo) : imgRobot}
                    alt="Green double couch with wooden legs"
                    objectFit="contain"
                    borderRadius="lg"
                  />
                </AspectRatio>
                <Stack mt="6" spacing="3">
                  <Heading size="md">{experiment.title}</Heading>
                  {/* <Text>TODO: Add Description</Text> */}
                </Stack>
              </CardBody>
              <CardFooter>
                <ButtonGroup spacing="2">
                  <Button
                    onClick={() => handleOpenExperiment(experiment.id)}
                    bgColor="brand.900"
                    color="white"
                  >
                    Open
                  </Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </Center>
  );
};
