import React, { useMemo } from 'react';
import { Button } from '@chakra-ui/react';
import { useDataContext } from 'context';

export const Table = ({ children }) => {
  const { application } = useDataContext();
  const hideDownloadButton = useMemo(
    () => application?.config?.hideDownloadButton,
    [application]
  );
  const id = `ID_${new Date().getTime()}`;

  const downloadTableAsCSV = () => {
    // Get the table element by its ID
    const table = document.getElementById(id);
    const rows = table.querySelectorAll('tr');

    // Create an array to store CSV data
    const csvData = [];

    // Loop through table rows and cells to extract data
    rows.forEach(row => {
      const rowData = [];
      row.querySelectorAll('th,td').forEach(cell => {
        rowData.push(cell.innerText);
      });
      csvData.push(rowData.join(','));
    });

    // Create a Blob with the CSV data
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv' });

    // Create a temporary link and trigger the download
    const a = document.createElement('a');
    a.href = URL.createObjectURL(csvBlob);
    a.download = 'table_data.csv';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {!hideDownloadButton && (
        <Button
          onClick={downloadTableAsCSV}
          colorScheme="teal"
          size="xs"
          marginBottom={2}
        >
          Download as CSV
        </Button>
      )}
      <table>{children}</table>
      {/* Table with ID will be used for download */}
      <table id={id} style={{ display: 'none' }}>
        {children}
      </table>
    </>
  );
};
